import { Container } from 'react-bootstrap';
import Home from './components/home/Home';
import { info } from './data';

function App() {
  return (
    <>
      <div className="fixed-header ">
        DINE-IN ONLY. Please make a reservation for dine-in:{' '}
        <a href={'tel:' + info.PHONE_NUM}>{info.PHONE_NUM}</a>. Thank you!
      </div>
      <Container fluid>
        <Home />
      </Container>
    </>
  );
}

export default App;
