import React from 'react';
import { Stack, Image } from 'react-bootstrap';

import picture from '../../assets/yugafu-frog.png';
import './about.styles.scss';

const About = () => {
  return (
    <Stack className="about">
      <div>
        <Image className="about-img" src={picture} fluid />
      </div>
      <div className="about-text">
        <h4>
          About the chef at <b>YUGAFU JAPANESE BISTRO</b>
        </h4>
        <p>
          Born in 1968 Hyogo, Japan, Toshiharu began his journey into the world
          of Japanese cuisine at 14 years old. He's studied in different regions
          of Japan including Hyogo, Osaka and Kyoto. Toshiharu arrived in Canada
          in 1991 and started his career at Vancouver's Kibune Sushi (open since
          1982), training under his master, Hideyoshi Endo. With the support of
          his colleagues and customers he pursued his training. In 2021, his
          dream turned into a reality with the opening of "Yugafu".
        </p>
        <p className="quote">
          "This is not just a Japanese restaurant, this is Japan."
        </p>
        <p>
          1968年,
          日本兵庫県生まれ。14歳の時に日本料理の世界に入る。その後兵庫、大阪、京都で日本の伝統料理を修行。1991年カナダ
          バンクーバーの貴船寿司（創業1982年）で遠藤秀峰親方の下で親方修行,
          同僚、お客様の支えのもと修行に励む。2021年「夢」を「現実」にゆがふオープン.
        </p>
        <p className="quote">
          ”ここは単にジャパニーズレストランでばありません、
          ここは日本そのものです”
        </p>
      </div>
    </Stack>
  );
};

export default About;
