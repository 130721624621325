import React from 'react';
import { Row, Image } from 'react-bootstrap';

//import InstagramMedia from '../instagram/InstagramMedia';

import { linkUrl } from '../../data';
import fish from '../../assets/yugafu-fish.png';
import './menuGallery.styles.scss';

const MenuGallery = () => {
  return (
    <div className="menu-container">
      <Image src={fish} fluid className="menu-gallery-img" />
      <Row>
        <h6 className="menu-gallery-text">
          Go{' '}
          <a href={linkUrl.MENU} target="_blank" rel="noreferrer noopener">
            here
          </a>{' '}
          to see our most updated menu or browse our{' '}
          <a href={linkUrl.INSTA} target="_blank" rel="noreferrer noopener">
            INSTAGRAM
          </a>{' '}
          to see what kind of food we serve!
        </h6>
      </Row>

      {/* <Row>
        <InstagramMedia />
      </Row> */}
    </div>
  );
};

export default MenuGallery;
