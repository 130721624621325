const reviews = [
  {
    text: "If you never tried OMAKASE this would be perfect res to visit and explore. Chef is super nice and approachable, service is excellent. After my first visit I immediately booked my second. YES, THEY ARE THAT GOOD. I was not expecting much based on the price they offered, but I was wrong. The quality and quantity they provided is way beyond my expectations. After my meal at Yugafu I don't think I would ever go to anywhere else",
    rating: 5,
  },
  {
    text: 'We had the omakase and it was delicious ! The chef is very talented, the food was delicious and the atmosphere was peaceful. Seems like a family business. Our favorite was the blue fin, yum!',
    rating: 5,
  },

  {
    text: 'Must book it ahead of time ! They are super busy, I booked a month in advance. During the phone call I only briefly asked if there was anything special for birthdays, but the waitress said no which was completely fine but when we got there the sushi chef made us these super pretty sashimi flowers!! I guess the waitress put a note on our reservation?? But anyways, it was on the house and just so thoughtful!! The chef and waitress are super super sweet thoughtful. Definitely recommend and will go again!',
    rating: 5,
  },

  {
    text: 'The quality of the fish is so good and they get almost all of it imported from Japan. The service was great as it looks like the whole place is run by just 2 people.',
    rating: 4,
  },
];

export default reviews;
