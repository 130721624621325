import React from 'react';
import { Row, Col, Image, Carousel } from 'react-bootstrap';

import Banner from '../banner/Banner';
import About from '../about/About';
import MenuGallery from '../menu-gallery/MenuGallery';
import CustomerReview from '../review/CustomerReview';
import ReservationNotice from '../reservation-notices/ReservationNotices'


import banner1 from '../../assets/banner_1.jpg';
import banner2 from '../../assets/banner_2.jpg';

import './home.styles.scss';

const Home = () => {
  return (
    <Row md={2} sm={1}>
      <Col className="carousel-container" lg={4} md={5} sm={12}>
        <Carousel controls={false} indicators={false}>
          <Carousel.Item className="yugafu-banner" as={Image} src={banner1} />
          <Carousel.Item className="yugafu-banner" as={Image} src={banner2} />
        </Carousel>
        <Banner />
      </Col>
      <Col
        className="content-container text-fade bg-flower"
        lg={{ offset: 4, span: 8 }}
        md={{ offset: 5, span: 7 }}
        sm={{ offset: 0, span: 12 }}
      >
        <Row sm={1}>
          <About />
        </Row>
        <Row sm={1}>
          <MenuGallery />
        </Row>
        <Row sm={1}>
          <CustomerReview />
        </Row>
        <Row sm={1}>
          <ReservationNotice />
        </Row>
      </Col>
    </Row>
  );
};
export default Home;
