import React from 'react';
import { Stack, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faGoogle,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons';

import { linkUrl, info } from '../../data';
import yugafu from '../../assets/yugafu-title.png';
import './banner.styles.scss';

const Banner = () => {
  // Font Awesome Icons
  const insta = <FontAwesomeIcon icon={faInstagram} size="2x" />;
  const google = <FontAwesomeIcon icon={faGoogle} size="2x" />;
  const facebook = <FontAwesomeIcon icon={faFacebookF} size="2x" />;

  return (
    <div className="banner-container">
      <div className="title-container text-fade">
        <Image fluid src={yugafu} />
      </div>
      <div className="banner-content text-fade">
        <Stack className="sns-icons" direction="horizontal" gap={3}>
          <a
            className="banner-text"
            href={linkUrl.INSTA}
            target="_blank"
            rel="noopener noreferrer"
          >
            {insta}
          </a>
          <a
            className="banner-text"
            href={linkUrl.FB}
            target="_blank"
            rel="noopener noreferrer"
          >
            {facebook}
          </a>
          <a
            className="banner-text"
            href={linkUrl.GOOGLE}
            target="_blank"
            rel="noopener noreferrer"
          >
            {google}
          </a>
        </Stack>
        <div className="banner-text banner-bold">YUGAFU JAPANESE BISTRO</div>
        <a
          className="banner-text"
          href={linkUrl.MAPS}
          target="_blank"
          rel="noopener noreferrer"
        >
          {info.ADDRESS}
        </a>
        <p className="banner-text">
          MONDAY: Closed <br />
          TUESAY - SUNDAY: {info.OPEN_HOUR} ~ {info.CLOSE_HOUR}
          <br />
          <br />
          Reservations:{' '}
          <a className="banner-text" href={'tel:' + info.PHONE_NUM}>
            {info.PHONE_NUM}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Banner;
