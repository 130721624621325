// prettier-ignore
export const linkUrl = {
  INSTA: 'https://www.instagram.com/yugafujapanesebistro/?hl=en',
  GOOGLE: 'https://www.google.com/search?q=yugafu+japanese+bistro&rlz=1C1CHBF_enCA980CA980&oq=&aqs=chrome.1.69i59i450l8.393686j0j7&sourceid=chrome&ie=UTF-8',
  FB: 'https://www.facebook.com/pages/category/Restaurant/Yugafu-Japanese-Bistro-101554721814907/',
  MAPS: 'https://goo.gl/maps/9oqhPRTdX8YgfpBa9',
  MENU: 'https://www.google.com/maps/uv?pb=!1s0x5485d18e7d365ab9%3A0x9f9fcfb5a61df367!3m1!7e115!4shttps%3A%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipOCpJ66VevPJEQ3hbNLEAQZNytFdIZE5MOjpE5S%3Dw165-h220-k-no!5syugafu%20japanese%20bistro%20-%20Google%20Search!15sCgIYIQ&imagekey=!1e10!2sAF1QipPxSb7-VEUbWPfloEKL2rYx10984KgAayuo0IKF&hl=en&sa=X&ved=2ahUKEwiQo_TplID2AhWKHTQIHUw_AoYQoip6BAg0EAM'
};

export const info = {
  OPEN_HOUR: '5 PM',
  CLOSE_HOUR: '9 PM',
  ADDRESS: '18818 68 AVE #103, Surry BC',
  PHONE_NUM: '778-575-5323',
};
