/**
 * This component will have nice looking review box
 * Reviews are from Google Reviews
 * Will be hardcoded text
 */

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuoteLeft,
  faQuoteRight,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import { Image } from 'react-bootstrap';

import reviews from './reviews';
import yugafuDrink from '../../assets/yugafu-drink.png';
import './customerReview.styles.scss';

const CustomerReview = () => {
  const [review, setReview] = useState(reviews[0]);
  const [index, setIndex] = useState(0);

  // update index every certain time, if max, reset to 0
  useEffect(() => {
    const timer = setInterval(
      () => setIndex((i) => (i + 1) % reviews.length),
      10000
    );
    return () => clearInterval(timer);
  }, []);

  // if index updates, set state
  useEffect(() => {
    setReview(reviews[index]);
  }, [index]);

  // Display number of stars depending on rating.
  const stars = [...Array(review.rating)].map((e, i) => (
    <FontAwesomeIcon className="stars" key={i} icon={faStar} size="lg" />
  ));

  return (
    <div className="review-container">
      <Image src={yugafuDrink} fluid className="yugafu-drink-img" />

      <div className="review-box review-fade">
        <div className="progress-bar"></div>
        <FontAwesomeIcon
          className="fa-icon left"
          icon={faQuoteLeft}
          size="lg"
        />
        <FontAwesomeIcon
          className="fa-icon right"
          icon={faQuoteRight}
          size="lg"
        />
        <p className="review-text">{review.text}</p>
        <div className="stars-container">{stars}</div>
      </div>
    </div>
  );
};

export default CustomerReview;
